import { PageProps } from 'gatsby';
import React, { FC } from 'react';

import Calendar from '~/components/Calendar';
import Chat from '~/components/Chat';
import Subscription from '~/components/Subscription';
import ToggleMenu from '~/components/ToggleMenu';
import IconWindowMenu from '~/icons/WindowMenu';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';

const DashboardPage: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
}) => (
  // const handleStartVideoRecording = () => {
  //   console.info(
  //     "navigator.userAgent.includes('iPhone'",
  //     navigator.userAgent.includes('iPhone'),
  //   );
  //   if (!navigator.userAgent.includes('iPhone')) {
  //     setHasVideo(true);
  //     setIsRecording(true);
  //     start({ audio: true, video: true });
  //   } else {
  //     const file = document.querySelector(
  //       '#input-file__image',
  //     ) as HTMLInputElement;
  //     if (file) {
  //       file.click();
  //     }
  //   }
  // };

  // const { t } = useTranslation();
  // const [inquiries, setInquiries] = useState<DocumentType<InquiryType>[]>();
  //
  // if (inquiries && inquiries.length > 0) {
  //   messages.push({
  //     notifications: inquiries.map(inquiry => ({
  //       date: inquiry.updatedAt,
  //       description: inquiry.messages[inquiry.messages.length - 1].text,
  //       icon: 'inquiries',
  //       id: inquiry.id,
  //       label: inquiry.contactName,
  //       subLabel: t('inquiry.item.nb-messages', {
  //         count: inquiry.messages.length,
  //       }),
  //       to: `/espaces/${espace.id}/inquiries/${inquiry.id}/update`,
  //     })),
  //     text: t('dashboard.inquiries.waiting.label', {
  //       count: inquiries.length,
  //     }),
  //   });
  // }
  // useEffect(() => {
  //   const inquiryData = new InquiryData({ espaceId: espace.id });
  //   const unsub = inquiryData.watch(
  //     docs => {
  //       setInquiries(docs);
  //     },
  //     {
  //       wheres: {
  //         archived: false,
  //         parent: '',
  //       },
  //     },
  //   );
  //
  //   return () => {
  //     if (unsub) {
  //       unsub();
  //     }
  //   };
  // }, [espace.id]);

  <TemplateEspace espace={espace} pathname={location.pathname} user={user}>
    <div className="mt-6 flex md:mx-6">
      <div className="flex-1">
        <ToggleMenu iconLeft={IconWindowMenu} variant="action" />
        <div className="mx-6 md:mx-0">
          <Chat user={user} />
        </div>
      </div>
      <div className="hidden md:block">
        <div className="sticky top-6">
          <Calendar
            onSelect={() => {
              console.info('selected');
            }}
          />
        </div>
      </div>
    </div>
    <div className="md:px-6">
      <div className="flex flex-col space-y-6">
        {/* <Message text="Merci, passe une belle journée." type="sender" /> */}
        {/* <div className="w-full"> */}
        {/*  {undefined !== inquiries && inquiries.length > 0 && ( */}
        {/*    <Notification */}
        {/*      description={t('dashboard.inquiries.waiting.label', { */}
        {/*        count: inquiries.length, */}
        {/*      })} */}
        {/*      icon="inquiries" */}
        {/*      label="Demandes de renseignements" */}
        {/*    /> */}
        {/*  )} */}
        {/* </div> */}

        <Subscription user={user} />
      </div>
    </div>
  </TemplateEspace>
);
export default requireEspace(DashboardPage);
