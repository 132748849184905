import { DocumentType, UserType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import Button from '~/components/Button';
import IconLoading from '~/icons/Loading';

// Convert a base64 string to Uint8Array.
// Must do this so the server can understand the VAPID_PUBLIC_KEY.
function urlB64ToUint8Array(base64String: string) {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/');
  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);
  for (let i = 0; i < rawData.length; i += 1) {
    outputArray[i] = rawData.charCodeAt(i);
  }

  return outputArray;
}

const Subscription: FC<{ user: DocumentType<UserType> }> = function ({ user }) {
  const [isAvailable, setIsAvailable] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [subscription, setSubscription] = useState<PushSubscription>();

  useEffect(() => {
    let isMounted = true;

    if (
      Reflect.has(window, 'Notification') &&
      Reflect.has(navigator, 'serviceWorker')
    ) {
      switch (Notification.permission) {
        case 'granted': {
          navigator.serviceWorker
            .getRegistration()
            .then(registration => {
              if (registration) {
                return registration.pushManager.getSubscription();
              }

              return undefined;
            })
            .then(newSubscription => {
              if (newSubscription && isMounted) {
                setSubscription(newSubscription);
                // console.info('subscription', newSubscription);
              }

              return isMounted;
            })
            .catch(error => console.error(error.message));
          break;
        }

        case 'default': {
          setIsAvailable(true);
          break;
        }
      }
    }

    return () => {
      isMounted = false;
    };
  }, []);

  const handleRequestPermissionOnClick = async () => {
    setIsLoading(true);

    console.info('Notification.permission', Notification.permission);
    if (Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission();
      if ('denied' === permission) {
        console.info("L'utilisateur a refusé de recevoir des notifications");
      } else {
        console.info(
          "L'utilisateur n'a pas donné son accord pour recevoir des notifications",
        );
      }

      setIsLoading(false);

      return false;
    }

    console.info("L'utilisateur a accepté de recevoir des notifications");
    const registration = await navigator.serviceWorker.getRegistration();
    if (!registration) {
      console.error("Aucun service worker n'est inscrit");

      setIsLoading(false);

      return false;
    }

    const subscribed = await registration.pushManager.getSubscription();
    if (subscribed) {
      console.info("L'utilisateur est déjà abonné");
      // setIsLoading(false);
      // console.info(subscribed);
      await subscribed.unsubscribe();

      // return true;
    }

    const newSubscription = await registration.pushManager.subscribe({
      applicationServerKey: urlB64ToUint8Array(
        String(process.env.GATSBY_VAPID_PUBLIC_KEY),
      ),
      userVisibleOnly: true,
    });

    const url = `${process.env.GATSBY_INNEDIT_API_URL}/users/${user.id}/push-manager/add-subscription`;

    await fetch(url, {
      body: JSON.stringify(newSubscription),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    setIsLoading(false);

    return true;
  };

  if (!isAvailable || subscription) {
    return null;
  }

  return (
    <div className="mt-6 flex items-center justify-center">
      <Button
        color="neutral"
        iconLeft={isLoading ? IconLoading : undefined}
        onClick={handleRequestPermissionOnClick}
        text="Autoriser de recevoir des notifications"
        variant="outline"
      />
    </div>
  );
};

export default Subscription;
